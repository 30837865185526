<template>
  <div>
    <div
      :class="{ active: loading }"
      class="ui inverted dimmer"
    >
      <div class="ui loader" />
    </div>
    <div
      id="message"
      class="fullwidth"
    >
      <div
        v-if="error"
        class="ui negative message"
      >
        <p><i class="close icon" /> {{ error }}</p>
      </div>
      <div
        v-if="success"
        class="ui positive message"
      >
        <i
          class="close icon"
          @click="success = null"
        />
        <p>{{ success }}</p>
      </div>
    </div>
    <div class="fourteen wide column">
      <form
        id="form-symbology-edit"
        action=""
        method="post"
        enctype="multipart/form-data"
        class="ui form"
      >
        <h1 v-if="project && feature_type">
          Éditer la symbologie du type de signalement "{{ feature_type.title }}" pour le
          projet "{{ project.title }}"
        </h1>
        <SymbologySelector
          v-if="feature_type"
          :init-color="feature_type.color"
          :init-icon="feature_type.icon"
          :geom-type="feature_type.geom_type"
          @set="setDefaultStyle"
        />
        <div
          v-if="
            feature_type &&
              feature_type.customfield_set.length > 0 &&
              feature_type.customfield_set.some(el => el.field_type === 'list')
          "
        >
          <div class="ui divider" />
          <label
            id="customfield-select-label"
            for="customfield-select"
          >
            Personnaliser la symbologie d'une liste de valeurs:
          </label>
          <select
            id="customfield-select"
            v-model="selectedCustomfield"
            class="ui dropdown"
          >
            <option
              v-for="customfieldList of feature_type.customfield_set.filter(el => el.field_type === 'list')"
              :key="customfieldList.name"
              :value="customfieldList.name"
            >
              {{ customfieldList.label }}
            </option>
          </select>
        </div>
        <div v-if="selectedCustomfield">
          <div 
            v-for="option of feature_type.customfield_set.find(el => el.name === selectedCustomfield).options"
            :key="option"
          >
            <SymbologySelector
              :title="option"
              :init-color="feature_type.colors_style.value ?
                feature_type.colors_style.value.colors[option] ?
                  feature_type.colors_style.value.colors[option].value :
                  feature_type.colors_style.value.colors[option]
                : null
              "
              :init-icon="feature_type.colors_style.value ?
                feature_type.colors_style.value.icons[option] :
                null
              "
              :geom-type="feature_type.customfield_set.geomType"
              @set="setColorsStyle"
            />
          </div>
        </div>
        <div class="ui divider" />
        <button
          class="ui teal icon button margin-25"
          type="button"
          :disabled="!canSaveSymbology"
          @click="sendFeatureSymbology"
        >
          <i class="white save icon" />
          Sauvegarder la symbologie du type de signalement
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import frag from 'vue-frag';
import { isEqual } from 'lodash';

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import SymbologySelector from '@/components/feature_type/SymbologySelector.vue';

export default {
  name: 'FeatureTypeSymbology',

  directives: {
    frag,
  },

  components: {
    SymbologySelector
  },

  data() {
    return {
      loading: false,
      error: null,
      success: null,
      selectedCustomfield: null,
      form: {
        color: '#000000',
        icon: 'circle',
        colors_style: {
          fields: [],
          colors: {},
          icons: {},
          custom_field_name: '',
          value: {
            colors: {},
            icons: {}
          }
        },
      },
      canSaveSymbology: false
    };
  },

  computed: {
    ...mapState('projects', [
      'project'
    ]),
    ...mapState('feature_type', [
      'customForms',
      'colorsStyleList'
    ]),
    ...mapGetters('feature_type', [
      'feature_type'
    ]),
  },

  watch: {
    selectedCustomfield(newValue) {
      this.form.colors_style.custom_field_name = newValue;
    },
    feature_type(newValue) {
      if (newValue) {
        // Init form
        this.form.color = JSON.parse(JSON.stringify(newValue.color));
        this.form.icon = JSON.parse(JSON.stringify(newValue.icon));
        this.form.colors_style = {
          ...this.form.colors_style,
          ...JSON.parse(JSON.stringify(newValue.colors_style))
        };
      }
    },
    form: {
      deep: true,
      handler(newValue) {
        if (isEqual(newValue, {
          color: this.feature_type.color, 
          icon: this.feature_type.icon,
          colors_style: this.feature_type.colors_style
        })) {
          this.canSaveSymbology = false;
        } else {
          this.canSaveSymbology = true;
        }
      }
    }
  },

  created() {
    if (!this.project) {
      this.GET_PROJECT(this.$route.params.slug);
      this.GET_PROJECT_INFO(this.$route.params.slug);
    }
    this.SET_CURRENT_FEATURE_TYPE_SLUG(this.$route.params.slug_type_signal);
    if (this.feature_type) {
      this.initForm();
    } else {
      this.loading = true;
      this.GET_PROJECT_FEATURE_TYPES(this.$route.params.slug)
        .then(() => {
          this.initForm();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  methods: {
    ...mapMutations('feature_type', [
      'SET_CURRENT_FEATURE_TYPE_SLUG'
    ]),
    ...mapActions('feature_type', [
      'SEND_FEATURE_SYMBOLOGY',
      'GET_PROJECT_FEATURE_TYPES'
    ]),
    ...mapActions('projects', [
      'GET_PROJECT',
      'GET_PROJECT_INFO',
    ]),

    initForm() {
      this.form.color = JSON.parse(JSON.stringify(this.feature_type.color));
      this.form.icon = JSON.parse(JSON.stringify(this.feature_type.icon));
      this.form.colors_style = {
        ...this.form.colors_style,
        ...JSON.parse(JSON.stringify(this.feature_type.colors_style))
      };
      if (this.feature_type.colors_style && Object.keys(this.feature_type.colors_style.colors).length > 0) {
        this.selectedCustomfield =
          this.feature_type.customfield_set.find(
            el => el.name === this.feature_type.colors_style.custom_field_name
          ).name;
      }
    },

    setDefaultStyle(e) {
      const value  = e.value;
      this.form.color = value.color.value;
      this.form.icon = value.icon;
    },

    setColorsStyle(e) {
      const { name, value } = e;
      this.form.colors_style.colors[name] = value.color;
      this.form.colors_style.icons[name] = value.icon;
      this.form.colors_style.value.colors[name] = value.color;
      this.form.colors_style.value.icons[name] = value.icon;
    },

    sendFeatureSymbology() {
      this.loading = true;
      this.SEND_FEATURE_SYMBOLOGY(this.form)
        .then(() => {
          this.GET_PROJECT_FEATURE_TYPES(this.$route.params.slug)
            .then(() => {
              this.loading = false;
              this.success =
                'La modification de la symbologie a été prise en compte. Vous allez être redirigé vers la page d\'accueil du projet.';
              setTimeout(() => {
                this.$router.push({
                  name: 'project_detail',
                  params: {
                    slug: this.$route.params.slug,
                  },
                });
              }, 1500);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>

h1 {
  margin-top: 1em;
}

form {
  text-align: left;

  #customfield-select-label {
    cursor: pointer;
    font-weight: 600;
    font-size: 1.1em;
  }
  #customfield-select {
    width: 50% !important;
  }

}

</style>
