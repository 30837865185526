<template>
  <div>
    <div class="three fields">
      <div class="row-title">
        {{ title }}
      </div>
      <div class="required inline field">
        <label :for="form.color.id_for_label">{{ form.color.label }}</label>
        <input
          :id="form.color.id_for_label"
          v-model.lazy="form.color.value"
          type="color"
          required
          :name="form.color.html_name"
        >
      </div>
      <!-- <div class="required inline field">
        <label>Symbole</label>
        <button
          class="ui icon button picker-button"
          type="button"
          @click="openIconSelectionModal"
        >
          <font-awesome-icon
            :icon="['fas', form.icon]"
            :style="{ color: form.color.value || '#000000' }"
            class="icon alt"
          />
        </button>
      </div> -->
    </div>
    <div
      ref="iconsPickerModal"
      :class="isIconPickerModalOpen ? 'active' : ''"
      class="ui dimmer modal transition"
    >
      <div class="header">
        Sélectionnez le symbole pour ce type de signalement :
      </div>
      <div class="scrolling content">
        <div
          v-for="icon of iconsNamesList"
          :key="icon"
          :class="form.icon === icon ? 'active' : ''"
          class="icon-container"
          @click="selectIcon(icon)"
        >
          <i
            :class="`fa-${icon}`"
            class="icon alt fas"
          />
        </div>
      </div>
      <div class="actions">
        <div
          class="ui cancel button"
          @click="isIconPickerModalOpen = false;"
        >
          Fermer
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import faIconsNames from '@/assets/icons/fa-icons.js';

export default {
  name: 'SymbologySelector',

  props: {
    title: {
      type: String,
      default: 'Symbologie par défault :'
    },
    initColor: {
      type: String,
      default: '#000000'
    },
    initIcon: {
      type: String,
      default: 'circle'
    },
    geomType: {
      type: String,
      default: 'Point'
    }
  },

  data() {
    return {
      isIconPickerModalOpen: false,
      iconsNamesList: faIconsNames,
      form: {
        icon: 'circle',
        color: {
          id_for_label: 'couleur',
          label: 'Couleur',
          field: {
            max_length: 128, // ! Vérifier la valeur dans django
          },
          html_name: 'couleur',
          value: '#000000',
        },
      }
    };
  },

  watch: {
    form: {
      deep: true,
      handler(newValue) {
        this.$emit('set', {
          name: this.title === 'Symbologie par défault :' ? null : this.title,
          value: newValue
        });
      }
    }
  },

  created() {
    this.form.color.value = this.initColor;
    if (this.initIcon) this.form.icon = this.initIcon;
    this.$emit('set', {
      name: this.title,
      value: this.form
    });
  },

  methods: {
    openIconSelectionModal() {
      this.isIconPickerModalOpen = true;
    },

    selectIcon(icon) {
      this.form.icon = icon;
    }
  }
};
</script>

<style lang="less" scoped>

.fields {
  align-items: center;
  justify-content: space-between;
  margin-top: 3em !important;
}

.row-title {
  display: inline;
  font-size: 1.4em;
  width: 33%;
  text-align: left;
  margin-left: 0.5em;
}

#couleur {
  width: 66%;
  cursor: pointer;
  box-shadow: 0 0 1px 1px rgb(189, 189, 189);
}

.picker-button {
  height: 50px;
  width: 50px;
  border-radius: 3px;
  box-shadow: 0 0 2px 1px rgb(131, 131, 131);
  .icon.alt {
    width: 30px;
    height: 30px;
  }
}
.picker-button:hover {
  box-shadow: 0 0 2px 1px rgb(165, 165, 165);
}

.modal {
  height: fit-content;
  .content {
    display: flex;
    flex-flow: row wrap;
    .icon-container {
      padding: 7px;
      .icon.alt {
        color: rgb(75, 75, 75);
        width: 30px;
        height: 30px;
      }
    }
    .icon-container:hover {
      cursor: pointer;
      background-color: rgba(130, 216, 219, 0.589);
    }
    .icon-container.active {
      background-color: rgba(130, 216, 219, 0.589);
    }
  }
}

</style>
